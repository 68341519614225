.Header-Menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Header-Menu-Item {
    margin-left: 16px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    fill: #aad6e8;
}