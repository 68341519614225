.ScoreBar {
  width: 100%;
  margin-bottom: 16px;
}

.ScoreBar-Sympathy {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
}

.ScoreBar-Sympathy p {
  position: absolute;
  bottom: 6px;
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: 700;
}

.ScoreBar-Progress-Wrapper {
  border-radius: 5px;
  overflow: hidden;
  height: 5px;
  width: 100%;
}

.ScoreBar-Progress {
  height: 100%;
  background-color: blue;
}

.ScoreBar-Label {
  display: flex;
  justify-content: space-between;
}

.ScoreBar-Label p {
  padding: 0;
}

.Scores-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 26em;
  justify-self: center;
  width: 100%;
}

.ProgressBar-Wrapper {
  position: relative;
}

.ProgressBar-Background {
}

.ProgressBar-Value {
  position: absolute;
  stroke-dashoffset: 180px;
  stroke-dasharray: 182px;
  left: 0;
  top: 0;
}

.Main-Rankings {
  display: grid;
  justify-content: center;
  grid-template-areas:
    "b"
    "a"
    "c";
  grid-template-rows: min-content;
  grid-template-columns: minmax(0, 1fr) 0px;
}

.Main-Rankings-Attribute {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.Main-Rankings-Attribute p {
  color: #00143c;
  font-size: 14px;
  min-height: 34px;
  padding: 0;
  margin: 8px 0;
  inline-size: 90px;
  overflow-wrap: break-word;
}

.Main-Rankings-Attributes-Wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-content: space-between;
  justify-content: space-between;
  gap: 22px;
}

.Main-Rankings-Description {
  color: #081838;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding: 0;
}

.Main-Rankings-Description-Item p {
  padding: 0;
  margin: 0;
}

.Main-Rankings-Description-Item-Label {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #40506e;
}

.Main-Rankings-Description-Item-Value {
  text-align: left;
  color: #00143c;
}

.Main-Rankings-Description-Wrapper {
  display: flex;
  justify-content: space-between;
}

.Recommendations-Wrapper h4 {
  margin-top: 0;
}

@media screen and (max-width: 1100px) {
  .Scores-Wrapper {
    margin-bottom: 2em;
  }

  .Main-Rankings-Descriptions {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 500px) {
  .Scores-Wrapper {
    margin-bottom: 1em;
  }

  .Main-Rankings-Descriptions {
    margin-bottom: 1em;
  }
}

@media screen and (min-width: 500px) {
  .Main-Rankings-Attributes-Wrapper {
    grid-template-columns: repeat(4, auto);
  }
}

@media screen and (min-width: 750px) {
  .Main-Rankings-Attributes-Wrapper {
    grid-template-columns: repeat(3, auto);
  }
}

@media screen and (min-width: 850px) {
  .Main-Rankings-Attributes-Wrapper {
    grid-template-columns: repeat(4, auto);
  }
}

@media screen and (min-width: 1100px) {
  .Main-Rankings {
    grid-template-areas:
      "a b"
      "c c";
    grid-template-rows: min-content;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }
}

@media screen and (min-width: 1700px) {
  .Main-Rankings {
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-areas: "a b c";
    gap: 4em;
  }
}

@media screen and (min-width: 2100px) {
  .Main-Rankings {
    grid-template-columns: 2fr 1fr 2fr;
  }
}
