.Main-Preview {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 72px;
}

.Main-Preview p {
  max-width: 65ch;
}

.Main-Preview-Image {
  width: 100%;
  height: auto;
  max-height: 609px;
}

.Main-Image-Wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background-color: #e0e6f2;
  width: 100%;
  height: 300px;
}

.Main-Image {
  width: auto;
  height: 100%;
  object-fit: cover;
}

@container (min-height: 300px) {
  .Main-Image {
    width: 100%;
    height: 100%;
  }
}

.Main-Persons-Section {
  margin: 24px 0;
}

.Main-Persons-Section-Person-Wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 2em;
}

.Main-Persons-Section-Person {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #e0e6f2;
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid #e0e6f2;
  overflow: hidden;
}

.Main-Persons-Section-Person img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.Main-Persons-Section-Selected-Person {
  border: 3px solid #4267b2;
  box-shadow: 0 0 7px 1px #4267b282;
}

@media screen and (max-width: 950px) {
  .Main-Persons-Section {
    margin: 16px 0;
  }
}

@media screen and (max-width: 775px) {
  .Main-Persons-Section-Person-Wrapper {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 1.5em;
  }
}

@media screen and (max-width: 450px) {
  .Main-Persons-Section-Person-Wrapper {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
.Main-Error-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Main-Error-Image {
  width: 320px;
  height: auto;
}
