.SettingsOverlay-Wrapper {
  display: flex;
  background-color: white;
  border-radius: 9px;
  min-width: 32em;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px #00000036;
  position: relative;
}

.SettingsOverlay-Wrapper p {
  text-align: left;
}

.SettingsOverlay-Sidebar {
  background-color: #d6def2;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.SettingsOverlay-Sidebar-Item {
  text-align: left;
  border-radius: 7px;
  padding: 0.4em 1em;
  margin: 0.3em 1em;
  box-sizing: border-box;
  transition: background-color 100ms ease-in-out;
}

.SettingsOverlay-Sidebar-Item:hover {
  background-color: #b1bfe3;
}

.SettingsOverlay-Sidebar button {
  font-size: 1em;
}

.SettingsOverlay-Sidebar-Title {
  color: #000;
  margin: 0 0 1em 0;
  padding: 0 1.4em;
  font-size: 1.4em;
}

.SettingsOverlay-Sidebar-Title-Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SettingsOverlay-Sidebar-Title-Wrapper button {
  display: none;
  margin: 0 0 1em 0;
  padding: 0 1.4em;
}

.SettingsOverlay-Content {
  width: 100%;
  height: 100%;
  padding: 1em 2em;
}

.SettingsOverlay-Content-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.7em;
}

.SettingsOverlay-Content-Header-Title {
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 1.2em;
}

.SettingsOverlay-Content p {
  margin: 0;
  padding: 0;
  color: black;
  font-weight: 400;
  margin-bottom: 10px;
}

p.Error {
  color: red;
  font-size: 1em;
  font-weight: 600;
  margin-top: 10px;
}

.SettingsOverlay-Content-Main button {
  background: #4267b2;
  height: 50px;
  padding: 0 10px;
  margin-left: 5px;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  margin-top: 0;
}

@media screen and (max-width: 530px) {
  .SettingsOverlay-Wrapper {
    max-width: 32em;
    flex-direction: column;
    width: calc(100vw - 2em);
    min-width: unset;
  }

  .SettingsOverlay-Content {
    width: unset;
  }

  .SettingsOverlay-Sidebar-Title-Wrapper button {
    display: block;
  }

  .SettingsOverlay-Content-Header button {
    display: none;
  }
}
