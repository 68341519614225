.Login-Container {
  min-height: var(--app-height);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.Main-Logo {
  width: 15em;
  height: 3em;
  margin: 3.5em 0 2em;
}

.Login-Field {
  width: 100%;
  max-width: 28rem;
  background-color: #edf2fb;
  border-radius: 12px;
  color: #282c34;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 16px;
  box-sizing: border-box;
  text-align: center;
  align-self: center;
  position: relative;
  overflow: hidden;
}

.Login-Failed-Text {
  color: red;
  font-size: 15px;
}

.Login-Input {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.Login-Inputfield {
  height: 50px;
  padding: 0 1em;
  font-size: 16px;
}

.Login-Button {
  height: 50px;
  border-radius: 7px;
  padding: 10px 12px;
  border: none;
  background-color: coral;
  color: #4a1805;
  font-weight: 600;
  font-size: 16px;
  margin-top: 50px;
}

.Login-Button:hover {
  color: #282c34;
  background-color: #ff9771;
}

.Login-Button:active {
  transform: scale(0.98);
}

.Login-Input-Label-Wrapper {
  margin: 20px 0 12px;
  font-weight: 600;
  font-size: 16px;
  color: #52617d;
}

.Login-Error-Box {
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
}

.Login-Error-Message {
  margin-left: 8px;
  color: #b94343;
}

.Login-Input-Error {
  outline: 2px solid #b94343;
}

.Login-Info-Text {
  text-align: center;
  font-size: 14px;
  padding: 16px 0;
}

.Reset-Success-Message {
  font-size: 14px;
  margin: 24px 0 0 0;
  color: #007122;
}

label {
  font-size: 16px;
  margin-bottom: 10px;
}

.Login-Info-Text a {
  color: #006df9;
}

input {
  border-radius: 7px;
  padding: 10px 12px;
  border: none;
  font-size: 18px;
  box-shadow: 1px 1px 2px #282c3469;
}

a {
  color: blue;
}

.Login-Failed-Text a {
  color: blue;
}
