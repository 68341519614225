.Dashboard {
  display: flex;
  flex-direction: row;
  background-color: aliceblue;
  max-height: var(--app-height);
}

.Dashboard-Sidebar {
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 32em;
  padding: 36px 0;
  box-sizing: border-box;
  min-width: 20em;
  background-color: red;
  box-shadow: 2px 2px 3px 0 rgba(66, 103, 178, 0.1);
  background-color: white;
}

.Dashboard-Content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3em 3em 0 3em;
  background-color: #f8faff;
  overflow-x: hidden;
}

.Dashboard-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #adadad;
}

.Dashboard-Main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3em;
  flex-grow: 1;
  box-sizing: border-box;
}

.Dashboard-Upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12em;
  padding: 0 36px;
  box-sizing: border-box;
}

.Dashboard-History {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 3em;
  overflow-y: hidden;
  margin-top: 30px;
}

.Dashboard-Sidebar-Animation {
  transition: bottom 300ms ease-in-out;
}

@media screen and (max-width: 750px) {
  .Dashboard-Sidebar {
    position: fixed;
    bottom: calc(-100vh + 5em);
    bottom: calc(-100dvh + 5em);
    left: 0;
    width: 100vw;
    height: var(--app-height);
    flex-direction: column-reverse;
    padding: 0 36px 36px 36px;
  }

  .Dashboard {
    padding-bottom: 5em;
    max-height: unset;
    overflow: unset;
  }

  .Dashboard-Sidebar-Open {
    bottom: 0;
    min-width: unset;
  }

  .Dashboard-History {
    margin-top: 10px;
  }

  .Dashboard-Upload {
    margin-top: 2em;
  }
}

@media screen and (max-width: 550px) {
  .Dashboard-Content {
    padding: 2.4em;
  }
}

@media screen and (min-width: 1600px) {
  .Dashboard-Main {
    padding: 0 3em;
  }
}
