.Collapsable-Wrapper {
  margin-bottom: 16px;
}

.Collapsable-Content {
  overflow: hidden;
  max-height: 40em;
  transition: max-height 500ms ease-in-out;
}

.Collapsable-Content p {
  padding: 0;
  margin: 8px 0 0 0;
  font-weight: 500;
  color: #081838;
  text-align: left;
  margin-left: 32px;
}

.Collapsable-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.Collapsable-Header-Label {
  display: flex;
  align-items: center;
}

.Collapsable-Header-Chevron {
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;
}

.Collapsable-Header-Chevron.opened {
  transform: rotate(180deg);
}

.Collapsable-Header-Label p {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #081838;
  text-align: left;
}
