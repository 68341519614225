.UploadField-Container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 2px dashed #4267b2;
}

.UploadField-Container:active {
    transform: scale(0.98);
}

.UploadField-Logo {
    margin-top: 12px;
}

p {
    color: #4267B2;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 0 12px;
}

.UploadField-Input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}