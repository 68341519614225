.Loader-Wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.shadow {
  background-color: #ffffffc4;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.display {
  display: flex;
}

.fade-in {
  animation: fadeIn 300ms ease-in-out;
}

.fade-out {
  animation: fadeOut 300ms ease-in-out;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
