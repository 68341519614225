.Overlay-Wrapper {
    display: none;
    position: fixed;
    background-color: #00000078;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 300ms ease-in-out;
}

.display {
    display: flex;
}

.fade-out {
    animation: fadeOut 300ms ease-in-out;
}