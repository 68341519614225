h2 {
  font-size: 22px;
  font-weight: 600;
  color: #071d49;
}

.HistoryList-Message {
  text-align: left;
  font-size: 14px;
}

.HistoryList-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px;
  padding: 0 36px;
}

.HistoryList-Seperator {
  height: 1px;
  background: rgba(66, 103, 178, 0.21);
  margin: 0 36px 1em;
}

.HistoryList-Items {
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  box-sizing: border-box;
  flex-shrink: 1;
  padding: 0px 18px 0 36px;
  box-sizing: border-box;
  margin: 0px 18px 0 0;
}

.HistoryList-Items::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.HistoryList-Items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
.HistoryList-Items::-webkit-scrollbar-thumb {
  background: #5078c9;
  border-radius: 8px;
}

/* Handle on hover */
.HistoryList-Items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 750px) {
  .Dashboard-Sidebar-Open .HistoryList-Toggle {
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 750px) {
  .HistoryList-Toggle {
    display: none;
  }
}
