.HistoryListItem-Container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #f5f8ff;
  border-radius: 7px;
  margin-bottom: 16px;
  overflow: hidden;
  border: 2px solid #00000008;
  cursor: pointer;
  padding: 0.5em;
  box-sizing: border-box;
  flex-shrink: 0;
}

.HistoryListItem-Title {
  font-size: 14px;
}

.HistoryListItem-Detail {
  font-size: 12px;
  color: #a9a9a9;
}

.HistoryListItem-Details {
  margin-top: -4px;
}

.HistoryListItem-Group {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.HistoryListItem-Preview {
  margin-right: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.HistoryListItem-Delete {
  color: #4267b2;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.HistoryListItem-Description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 12px;
}

.HistoryListItem-Delete:hover {
  color: #6f0000;
}

.HistoryListItem-Container-Selected {
  border: 2px solid #4267b2;
}

.HistoryListItem-Progressbar {
  position: absolute;
  height: 4px;
  width: 30%;
  left: 0;
  bottom: 0;
  background-color: green;
  transition: all 300ms ease-in-out;
}

.HistoryListItem-Image {
  width: 40px;
  height: 40px;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  justify-content: center;
  margin-right: 0.5em;
  flex: 0 0 auto;
  border-radius: 5px;
  position: relative;
}

.HistoryListItem-Image-Loader {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.2em;
  box-sizing: border-box;
}

.HistoryListItem-Image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.HistoryListItem-Container:last-child {
  margin-bottom: 0;
}

.fade {
  opacity: 0;
  transition-delay: 300ms;
}
