.App {
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  /*font-size: calc(10px + 2vmin);*/
  color: black;
  z-index: 0;
}

.App-Logo-Login {
  height: 100vh;
  width: 100vw;
  opacity: 0;
  position: absolute;
  object-fit: cover;
  transition: opacity 500ms ease-in-out;
}

.App-Logo {
  width: 15em;
  height: 3em;
}

.App-Logo-Dashboard {
  top: 30%;
  left: 30%;
  height: 65%;
  width: 70%;
  opacity: 0.5;
  z-index: 1;
  position: fixed;
}

.App-Logo path:first-child {
  animation: spin 60s linear infinite;
  transform-origin: 173px;
}

@media screen and (max-width: 420px) {
  .App-Logo {
    width: 100%;
    height: auto;
  }
}
